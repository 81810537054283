<template>
  <div>
    <div style="height: 600px;" ref="chart_data"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import moment from "moment"
export default {
  name: "ChartOrderTurnover",
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          x: [],
          y: [],
          y2: [],
        }
      }
    },
  },
  data() {
    return {
      charts: null
    }
  },
  watch: {
    data() {
      this.reload()
    }
  },
  mounted() {
    this.charts = echarts.init(this.$refs['chart_data'])
  },
  methods: {
    reload() {
      var option = {
        title: {
          text: '订单数据'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['营业额', '订单数']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.data.x.map(e => moment(e).format('MM/DD'))
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '营业额',
            data: this.data.y,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 2
            },
          },
          {
            name: '订单数',
            data: this.data.y2,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 2
            },
          }
        ]
      }
      this.charts.setOption(option)
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
